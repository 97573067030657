import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  isAuthenticated: boolean;
}

const initialState = { isAuthenticated: false } as User;

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getUser: (_, action: PayloadAction<User>) => {
      return action.payload;
    },
    removeUser: () => initialState,
  },
});

export const { getUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
