import React, { useState } from 'react';
import { Button } from 'ui-library/Button/Button';
import { TextInput } from 'ui-library/TextInput/TextInput';
import { Formik } from 'formik';
import * as Yup from 'yup';

interface Props {
  onSignin: (username: string, password: string) => unknown;
}

const validationSchema = Yup.object({
  email: Yup.string().required('Enter email to continue'),
  password: Yup.string().required('Enter password to continue'),
});

export const Signin = ({ onSignin }: Props) => {
  const onSubmit = (values: { email: string; password: string }) => {
    onSignin(values.email, values.password);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '576px', width: '576px' }}>
        <h1>Welcome</h1>
        <p className='info-text'>Sign in to your account</p>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={onSubmit}>
          {({ values, errors, touched, setTouched, setFieldValue, handleSubmit, isSubmitting }) => (
            <div>
              <TextInput label='Email address' value={values.email} setValue={(val) => setFieldValue('email', val)} />
              <TextInput label='Password' value={values.password} setValue={(val) => setFieldValue('password', val)} />
              <Button
                loading={isSubmitting}
                className='mt-4'
                title='Sign in'
                id='signin-submit-btn'
                onClick={handleSubmit}
              />
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
