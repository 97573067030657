import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = 'dark';

const themeSlice = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {
    toggleTheme: (_, action: PayloadAction<string>) => {
      return action.payload === 'dark' ? 'light' : 'dark';
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
