import React from 'react';
import './Button.scss';
import { ClipLoader } from 'react-spinners';

interface Props {
  id: string;
  title: string;
  onClick: () => unknown;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  position?: 'center' | 'left' | 'right';
  loading?: boolean;
}

export const Button = ({
  title,
  onClick,
  className,
  type = 'button',
  disabled = false,
  id,
  position = 'center',
  loading = false,
}: Props) => {
  return (
    <div className={`ui-button ${className}`} style={{ justifyContent: position }}>
      <button id={id} disabled={disabled} className='button' type={type} onClick={onClick}>
        {loading ? (
          <ClipLoader color={'#ddd'} size={23} speedMultiplier={0.5} cssOverride={{ borderWidth: '2px' }} />
        ) : (
          title
        )}
      </button>
    </div>
  );
};
