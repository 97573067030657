import React, { Dispatch, SetStateAction } from 'react';
import './TextInput.scss';

interface Props {
  label: string;
  value: string | number;
  setValue: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
}

export const TextInput = ({ label, value, setValue, placeholder, disabled = false, onBlur, onFocus }: Props) => {
  return (
    <div className='ui-text-input'>
      <label id='text-input-title' htmlFor='text-input' className='text-input-label'>
        {label}
      </label>
      <input
        type='text'
        placeholder={placeholder}
        id='text-input'
        className='text-input'
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};
