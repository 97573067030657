import { useNavigate, Route, Routes } from 'react-router-dom';
import Header from 'components/Header/Header';
import './App.scss';
import Profile from 'routes/Profile';
import { useSignin } from 'hooks/useSignin';
import { useAppSelector } from 'store';

const App = () => {
  const navigate = useNavigate();
  const theme = useAppSelector((state) => state.theme);
  const { signIn, signOut } = useSignin();

  return (
    <div className={`mimidev-${theme}`}>
      <Header
        onLogo={() => navigate('/')}
        onSignin={async (username, password, callback) => {
          await signIn(username, password);
          callback();
        }}
        onSignout={() => {
          signOut();
          navigate('/');
        }}
      />
      <div className='main-container' id='main-container' role='main'>
        <div className='routes'>
          <Routes>
            <Route
              path='/'
              element={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>}
            />
            <Route path='profile' element={<Profile />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
