import React from 'react';
import MimiAvatar from 'assets/images/avatar.jpeg';

const Profile = () => {
  return (
    <div>
      <img
        style={{
          width: '80px',
          height: '80px',
          borderRadius: '50px',
          border: '1px double bisque',
          padding: '5px',
        }}
        alt='avatar'
        src={MimiAvatar}
      />
      <p>
        Amongst the things i <span style={{ color: '#ce2029', fontSize: '25px' }}>❤</span>:
      </p>
      <ul>
        <li>freshly baked bread and capuccino with oat milk :)</li>
        <li>long walks in a park, together with my wife and son</li>
        <li>meditation and self discovery, observing nature and Her perfection</li>
        <li>playing music and doing ceramics</li>
        <li>coding and the zen of coding: clean, simple, evident, egoless</li>
        <li>going to the essence in everything i do</li>
        <li>who i am, having nothing to prove to the world</li>
        <li>spicy food 🌶️🌶️</li>
        <li>good company, open hearted people</li>
        <li>repetition, as the mother of learning</li>
        <li>the unexpected</li>
        <li>the journey of trying to prove the impossible posible</li>
        <li>hugging trees 🌳</li>
      </ul>
      <p>PS. The order is not important</p>
    </div>
  );
};

export default Profile;
