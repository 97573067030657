import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

export const useSignin = () => {
  const signUp = async () => {
    try {
      const { user } = await Auth.signUp({
        username: 'mimi',
        password: 'shankar108',
        attributes: {
          email: 'sturzamimi@gmail.com',
          nickname: 'mimiji',
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
      console.log(user);
    } catch (error) {
      console.log('error signing up:', error);
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (error) {
      //   username = 'mimi'; password = 'shankar108';
      const user = await Auth.signIn(username, password);
      const { accessToken } = user.signInUserSession;
      console.log({ accessToken: accessToken.jwtToken });
      const decoded = jwt_decode(accessToken.jwtToken);
      console.log({ decoded });
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      console.log('error signing out: ', e);
    }
  };

  const confirmSignUp = async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  };

  return { signIn, signOut, signUp, confirmSignUp };
};
