import React, { useState } from 'react';
import './Header.scss';
import styles from '../../../src/exports.module.scss';
import { ReactComponent as LoginIcon } from '../../assets/icons/login-icon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout-icon.svg';
import { Signin } from 'components/Signin/Signin';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser } from 'store/user/user.slice';

interface HeaderProps {
  onSignin: (username: string, password: string, callback: () => unknown) => unknown;
  onLogo: () => unknown;
  onSignout: () => unknown;
}

const Header = ({ onSignin, onSignout, onLogo }: HeaderProps) => {
  const [toggle, setToggle] = useState(false);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const onLoginClick = () => {
    setToggle(!toggle);
  };

  const onSigninClick = (username: string, password: string) => {
    onSignin(username, password, () => {
      dispatch(getUser({ isAuthenticated: true }));
      setToggle(!toggle);
      navigate('/profile');
    });
  };

  const onSignoutClick = () => {
    dispatch(getUser({ isAuthenticated: false }));
    onSignout();
  };

  return (
    <div className='ui-header' id='ui-header'>
      <button onClick={onLogo} className='title-btn'>
        Mimi<span style={{ fontSize: '1.8rem', color: styles['selected'] }}>dev</span>
      </button>
      <button
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className='login-btn'
        onClick={isAuthenticated ? onSignoutClick : onLoginClick}>
        {isAuthenticated ? (
          <LogoutIcon height={37} color={toggle || hover ? styles['selected'] : styles['main-text']} />
        ) : (
          <LoginIcon height={37} color={toggle || hover ? styles['selected'] : styles['main-text']} />
        )}
      </button>
      {toggle && (
        <div className='login-form'>
          <Signin onSignin={onSigninClick} />
        </div>
      )}
    </div>
  );
};

export default Header;
